<template>
  <div class="refund_detail">
    <div class="nav">
      <div class="nav_title">退款详情</div>
      <div class="nav_left_btn" @click="toBack" v-if="isCancel">
        <img :src="cancel" alt width="15" height="25" />
        <!-- <span class="back">返回</span> -->
      </div>
    </div>
    <!-- <div class="detail_top">
      <div class="left" @click="toBack" v-if="isCancel">
        <img :src="cancel" alt />
      </div>
      <div class="right">退款详情</div>
    </div> -->
    <div style="height: calc(100vh - 40px);overflow-y: scroll;">
      <ul class="list" v-for="(item,index) in detail" :key="index">
        <li>
          <p>退款时间</p>
          <p>{{item.refundTime}}</p>
        </li>
        <li>
          <p>订单号</p>
          <p>{{item.orderNo}}</p>
        </li>
        <li>
          <p>退款金额</p>
          <p>￥{{(item.amount / 100).toFixed(2)}}</p>
        </li>
        <li>
          <p>退款状态</p>
          <p>{{item.refundType===0?'退款中':item.refundType===1?'退款成功':item.refundType===2?'退款失败' : '退款审核中'}}</p>
        </li>
        <li v-if="item.refundType===2">
          <p>失败原因</p>
          <p>{{item.errorMsg}}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { h5BatchRefundAPI, getUserSystemSetupAPI } from "@/api/api";
import cancel from "@/assets/cancel.png";

export default {
  data() {
    return {
      cancel,
      detail: [],
      user: {},
      isCancel: false,
      newDetail: [],
      isVerify: false,
    };
  },
  created() {
    this.detail = JSON.parse(this.$route.query.detail);
    this.detail.map((item) => {
      // 0 退款中  1 退款成功  2 退款失败 3 审核中
      this.$set(item, "refundType", 0);
    });
    this.user = JSON.parse(sessionStorage.getItem("personInfo"));
    this.getUserSystemSetupFn();
    setTimeout(() => {
      this.h5RefundFn();
    }, 2000);
  },
  methods: {
    getDateFn(time) {
      //将时间戳格式转换成年月日时分秒
      var date = new Date(time);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";

      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      var strDate = Y + M + D + h + m + s;
      return strDate;
    },
    toBack() {
      this.$router.back();
    },
    h5RefundFn() {
      this.newDetail = JSON.parse(JSON.stringify(this.detail));
      this.newH5RefundFn();
    },
    newH5RefundFn() {
      if (!this.isVerify) {
        if (this.newDetail.length !== 0) {
          h5BatchRefundAPI({
            userId: this.user.id,
            rechargeId: this.newDetail[0].id,
          }).then((res) => {
            if (res.code === 0) {
              if (res.data[0].errorMsg) {
                this.detail.map((item) => {
                  if (item.orderNo === res.data[0].outTradeNo) {
                    item.refundType = 2;
                    this.$set(item, "errorMsg", res.data[0].errorMsg);
                    setTimeout(() => {
                      this.newDetail.splice(0, 1);
                      this.newH5RefundFn();
                    }, 1000);
                  }
                });
              } else {
                this.detail.map((item) => {
                  if (item.orderNo === res.data[0].outTradeNo) {
                    item.refundType = 1;
                    this.$set(item, "refundTime", res.data[0].refundTime);
                    setTimeout(() => {
                      this.newDetail.splice(0, 1);
                      this.newH5RefundFn();
                    }, 1000);
                  }
                });
              }
            }
          });
        } else {
          this.isCancel = true;
        }
      } else {
        this.detail.map((item) => {
          // 0 退款中  1 退款成功  2 退款失败 3 审核中
          this.$set(item, "refundType", 3);
        });
        this.isCancel = true;
      }
    },
    getUserSystemSetupFn() {
      getUserSystemSetupAPI(this.user.organization.id).then((res) => {
        if (res.code === 0 && res.data !== null) {
          if (JSON.parse(JSON.stringify(res.data.setting)) !== "{}") {
            const data = JSON.parse(res.data.setting);
            if (data.refund_set) {
              this.isVerify = data.refund_set.flag;
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.refund_detail {
  color: #333333;
  height: 100%;
  display: flex;
  flex-direction: column;
  width:100%;
  .nav {
    width: 100%;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 48px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 0.9rem 0.5rem 0.9rem 0.7rem;
      border-color: transparent;
      background-size: 1.2rem 2rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 0.3rem 0 0.8rem;
      top: 9px;
      left: 0px;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }
  .detail_top {
    width: 100%;
    background: rgba(255, 255, 255);
    display: flex;
    height: 40px;
    align-items: center;
    margin-bottom: 5px;
    .left {
      height: 30px;
      img {
        width: 30px;
        height: 30px;
      }
    }
    .right {
      height: 30px;
      line-height: 30px;
      font-size: 18px;
      font-weight: 900;
      color: #333333;
    }
  }
  .list {
    background: #fff;
    width: 100%;
    margin: 0 auto;
    color: #666666;
    font-size: 14px;
    border-bottom: 1px solid #eee;
    li {
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      p {
        margin: 0;
      }
      p:first-child {
        margin-left: 16px;
      }
      p:last-child {
        margin-right: 16px;
      }
    }
    li:last-child {
      padding-bottom: 20px;
    }
  }
}
</style>
